import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { GlobalConfig }     from 'resources/configs/global-config';

@inject(CustomHttpClient)
export class SourcesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns the file upload uri
     */
    fileUploadUri() {
        return GlobalConfig.generateApiUrl('legislation/management/sources/files/upload');
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('legislation/management/sources/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('legislation/management/sources/active');
    }

    /**
     * Returns all records by a given country
     *
     * @returns {*}
     */
    allByCountry(countryId) {
        return this.httpClient.get(`legislation/management/sources/all/country/${countryId}`);
    }

    /**
     * Returns active records by a given country
     *
     * @returns {*}
     */
    activeByCountry(countryId) {
        return this.httpClient.get(`legislation/management/sources/active/country/${countryId}`);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/management/sources/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('legislation/management/sources', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/management/sources/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/management/sources/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/management/sources/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/management/sources/destroy-selected', {ids: ids});
    }

}
