import { bindable, inject }                 from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { BaseViewModel }                    from 'base-view-model';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';

@inject(AppContainer, DiplomaClassificationsRepository)
export class ListDiplomaClassifications extends BaseViewModel {

    headerTitle = 'listing.legislation.diploma-classifications';
    listingId   = 'legislation-management-diploma-classifications-listing';

    @bindable newRecordRoute = 'legislation.management.diploma-classifications.create';

    @bindable repository;
    @bindable datatable;

    @bindable filterModel = {
        parent_id: null,
    };

    @bindable treeOptions = {
        click:    this.treeNodeClicked.bind(this),
        activate: this.treeNodeActivated.bind(this),
    };

    displayTree = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;

        this.treeOptions.repository = {
            source: this.repository,
            method: 'tree',
        };
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.management.diploma-classifications.manage',
            'legislation.management.diploma-classifications.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.legislation.diploma-classification',
            repository: this.repository,
            edit:       null,
            destroy:    {
                visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.diploma-classifications.manage', 'legislation.management.diploma-classifications.delete']) && (!row.isSticky || this.appContainer.authenticatedUser.isSystemReserved()),
                action:  'legislation.management.diploma-classifications.delete',
            },
            destroyed:  (row) => this.rowDestroyed(row),
            actions:    [
                {
                    tooltip: 'form.button.view',
                    visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.diploma-classifications.manage', 'legislation.management.diploma-classifications.view']),
                    icon:    'icon-file-eye',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.diploma-classifications.view',
                        params: {id: row.id},
                    }),
                },
                {
                    tooltip: 'form.button.edit',
                    visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.diploma-classifications.manage', 'legislation.management.diploma-classifications.edit']) && (!row.isSticky || this.appContainer.authenticatedUser.isSystemReserved()),
                    icon:    'icon-pencil7',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.diploma-classifications.edit',
                        params: {id: row.id},
                    }),
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'order',
                    name:  'diploma_classifications.order',
                    title: 'form.field.order',
                },
                {
                    data:  'name',
                    name:  'diploma_classifications.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'country',
                    name:  'countries.name',
                    title: 'form.field.country',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Handles tree node click event
     *
     * @param event
     * @param data
     */
    treeNodeClicked(event, data) {
        let activeNode = data.tree.getActiveNode();

        if (activeNode === data.node) {
            activeNode.setFocus(false);
            activeNode.setActive(false);

            this.filterModel.parent_id = null;

            this.datatable.instance.reload();

            return false;
        }
    }

    /**
     * Handles tree node activate event
     *
     * @param event
     * @param data
     */
    treeNodeActivated(event, data) {
        this.filterModel.parent_id = data.node.key;

        this.datatable.instance.reload();
    }

    /**
     * Handles `row destroyed even`
     *
     * @param row
     */
    rowDestroyed(row) {
        this.appContainer.eventAggregator.publish('reload-fancy-tree', {
            id: this.treeOptions.id,
        });
    }

    /**
     * Toggles the tree visibility
     */
    toggleTree() {
        this.treeOptions.activatedKey = this.filterModel.parent_id;

        this.displayTree = !this.displayTree;
    }

}
