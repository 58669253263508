import { inject }                     from 'aurelia-framework';
import { ConformityEvaluationStatus } from 'modules/legislation/models/conformity-evaluation-status';
import { BooleanStatus }              from 'modules/administration/models/boolean-status';
import { BooleanStatusesRepository }  from 'modules/administration/boolean-statuses/services/repository';

@inject(BooleanStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param booleanStatusesRepository
     */
    constructor(booleanStatusesRepository) {
        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ConformityEvaluationStatus}
     */
    model() {
        let model = new ConformityEvaluationStatus();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {

        this.acronym = {
            type:  'text',
            key:   'acronym',
            label: 'form.field.acronym',
            size:  3,
        };

        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  6,
        };

        this.icon = {
            type:  'text',
            key:   'icon',
            label: 'form.field.icon',
            size:  6,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         6,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.management.conformity-evaluation-statuses.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.acronym],
            [this.name],
            [this.icon],
            [this.status_id],
            [this.buttons],
        ];
    }
}
