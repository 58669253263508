import { inject }                    from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseFormViewModel }         from 'base-form-view-model';
import { DiplomaSettingsRepository } from 'modules/legislation/management/diploma-settings/services/repository';
import { FormSchema }                from 'modules/legislation/management/diploma-settings/edit/form-schema';

@inject(AppContainer, DiplomaSettingsRepository, FormSchema)
export class EditDiplomaSettings extends BaseFormViewModel {

    headerTitle = 'form.title.diploma-settings';
    formId      = 'legislation.management.diploma-settings.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.management.diploma-settings.manage',
            'legislation.management.diploma-settings.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.settings().then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.schema       = this.formSchema.schema(this);
            this.globalSchema = this.formSchema.globalSchema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.repository.update(this.model)
            .then(response => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                this.initialModel.assign(this.model);
            })
            .catch(console.log.bind(console));
    }

}
