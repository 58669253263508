import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class DiplomaSettingsRepository {

    baseUrl = 'legislation/management/diploma-settings';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns diploma settings
     *
     * @returns {*}
     */
    settings() {
        return this.httpClient.get(`${this.baseUrl}`);
    }

    /**
     * Updates the diploma settings
     *
     * @param data
     *
     * @returns {*}
     */
    update(data) {
        return this.httpClient.put(`${this.baseUrl}`, data);
    }

    /**
     * Uploads the given image
     *
     * @param data
     *
     * @returns {*}
     */
    uploadReportLogo(data) {
        return this.httpClient.post(`${this.baseUrl}/upload-report-logo`, data);
    }

}
