import { bindable, inject, PLATFORM }   from 'aurelia-framework';
import { AppContainer }                 from 'resources/services/app-container';
import { BaseViewModel }                from 'base-view-model';
import { ApplicabilityTypesRepository } from 'modules/legislation/management/applicability-types/services/repository';

@inject(AppContainer, ApplicabilityTypesRepository)
export class ListApplicabilityTypes extends BaseViewModel {

    headerTitle = 'listing.legislation.applicability-types';
    listingId   = 'legislation-management-applicability-types-listing';

    @bindable newRecordRoute = 'legislation.management.applicability-types.create';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.management.applicability-types.manage',
            'legislation.management.applicability-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.legislation.applicability-type',
            repository: this.repository,
            edit:       null,
            destroy:    {
                visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.applicability-types.manage', 'legislation.management.applicability-types.delete']) && (!row.isSticky || this.appContainer.authenticatedUser.isSystemReserved()),
                action:  'legislation.management.applicability-types.delete',
            },
            actions:    [
                {
                    tooltip: 'form.button.view',
                    visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.applicability-types.manage', 'legislation.management.applicability-types.view']),
                    icon:    'icon-file-eye',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.applicability-types.view',
                        params: {id: row.id},
                    }),
                },
                {
                    tooltip: 'form.button.edit',
                    visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.applicability-types.manage', 'legislation.management.applicability-types.edit']) && (!row.isSticky || this.appContainer.authenticatedUser.isSystemReserved()),
                    icon:    'icon-pencil7',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.applicability-types.edit',
                        params: {id: row.id},
                    }),
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'acronym',
                    name:  'applicability_types.acronym',
                    title: 'form.field.acronym',
                },
                {
                    data:  'name',
                    name:  'applicability_types.name',
                    title: 'form.field.designation',
                },
                {
                    data:       'icon',
                    name:       'applicability_types.icon',
                    title:      'form.field.icon',
                    type:       'stoplight',
                    icon_eval:  (row) => row.icon,
                    color_eval: (row) => 'text-primary',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
