import { inject }                           from 'aurelia-framework';
import { GlobalMessage }                    from 'modules/legislation/models/global-message';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';

@inject(DiplomaClassificationsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param diplomaClassificationsRepository
     */
    constructor(diplomaClassificationsRepository) {
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {GlobalMessage}
     */
    model() {
        let model = new GlobalMessage();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.subject = {
            type:  'text',
            key:   'subject',
            label: 'form.field.subject',
            size:  6,
        };

        this.body = {
            type:  'textarea',
            key:   'body',
            label: 'form.field.message',
            size:  12,
        };

        this.diploma_classifications = {
            type:         'fancytree',
            key:          'diploma_classifications',
            label:        'form.field.classifications',
            size:         12,
            options:      [],
            remoteSource: this.diplomaClassificationsRepository.originalTree.bind(this.diplomaClassificationsRepository, true),
            settings:     {
                repository: this.diplomaClassificationsRepository,
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.send-message',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.subject],
            [this.body],
            [this.diploma_classifications],
            [this.buttons],
        ];
    }

}
