import { inject }                    from 'aurelia-framework';
import { AuthenticatedUser }         from 'resources/services/authenticated-user';
import { TreeModal }                 from 'modules/administration/tree-modal/index';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { DiplomaClassification }     from 'modules/legislation/models/diploma-classification';
import { CountriesRepository }       from 'modules/administration/countries/services/repository';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';
import { UserGroupsRepository }      from 'modules/administration/user-groups/services/repository';
import { DialogService }             from 'aurelia-dialog';

@inject(AuthenticatedUser, CountriesRepository, BooleanStatusesRepository, UserGroupsRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param authenticatedUser
     * @param countriesRepository
     * @param booleanStatusesRepository
     * @param userGroupsRepository
     * @param dialogService
     */
    constructor(authenticatedUser, countriesRepository, booleanStatusesRepository, userGroupsRepository, dialogService) {
        this.authenticatedUser         = authenticatedUser;
        this.countriesRepository       = countriesRepository;
        this.booleanStatusesRepository = booleanStatusesRepository;
        this.userGroupsRepository      = userGroupsRepository;
        this.dialogService             = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {DiplomaClassification}
     */
    model() {
        let model = new DiplomaClassification();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns general form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    generalSchema(viewModel) {
        this.order = {
            type:  'text',
            key:   'order',
            label: 'form.field.order',
            size:  2,
        };

        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  6,
        };

        this.parent_id = {
            type:       'diploma-classifications-select',
            key:        'parent_id',
            label:      'form.field.branch',
            size:       8,
            required:   false,
            observers:  [
                (newValue) => this.country_id.instance.hide(isNumeric(newValue)),
            ],
            inputGroup: {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModal,
                        model:     {
                            repository: {
                                method: 'tree',
                                source: viewModel.repository,
                            },
                            onClick:    (event, data) => {
                                viewModel.model.parent_id = data.node.key;
                            },
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         4,
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
        };

        this.diploma_classification_mapping = {
            type:         'fancytree',
            key:          'diploma_classification_mapping',
            label:        'form.field.original-tree-mapping',
            size:         12,
            remoteSource: viewModel.repository.originalTree.bind(viewModel.repository),
            hidden:       isEmpty(this.authenticatedUser.currentTenant()),
            settings:     {
                repository: viewModel.repository,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
        };

        return [
            [this.order, this.name],
            [this.parent_id, this.country_id],
            [this.diploma_classification_mapping],
            [this.status_id],
        ];
    }

    /**
     * Returns daily legislation report addressees form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    dailyLegislationReportAddresseesSchema(viewModel) {
        this.daily_legislation_report_user_addressees = {
            type:     'users-duallistbox',
            key:      'daily_legislation_report_user_addressees',
            label:    'form.field.users',
            size:     6,
            required: false,
        };

        this.daily_legislation_report_group_addressees = {
            type:         'duallistbox',
            key:          'daily_legislation_report_group_addressees',
            label:        'form.field.groups',
            size:         6,
            options:      [],
            required:     false,
            remoteSource: this.userGroupsRepository.active.bind(this.userGroupsRepository),
        };

        return [
            [this.daily_legislation_report_user_addressees, this.daily_legislation_report_group_addressees],
        ];
    }

    /**
     * Returns monthly legislation report addressees form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    monthlyLegislationReportAddresseesSchema(viewModel) {
        this.monthly_legislation_report_user_addressees = {
            type:     'users-duallistbox',
            key:      'monthly_legislation_report_user_addressees',
            label:    'form.field.users',
            size:     6,
            required: false,
        };

        this.monthly_legislation_report_group_addressees = {
            type:         'duallistbox',
            key:          'monthly_legislation_report_group_addressees',
            label:        'form.field.groups',
            size:         6,
            required:     false,
            remoteSource: this.userGroupsRepository.active.bind(this.userGroupsRepository),
        };

        return [
            [this.monthly_legislation_report_user_addressees, this.monthly_legislation_report_group_addressees],
        ];
    }

    /**
     * Returns applicability report addressees form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    applicabilityReportAddresseesSchema(viewModel) {
        this.applicability_report_user_addressees = {
            type:     'users-duallistbox',
            key:      'applicability_report_user_addressees',
            label:    'form.field.users',
            size:     6,
            required: false,
        };

        this.applicability_report_group_addressees = {
            type:         'duallistbox',
            key:          'applicability_report_group_addressees',
            label:        'form.field.groups',
            size:         6,
            required:     false,
            remoteSource: this.userGroupsRepository.active.bind(this.userGroupsRepository),
        };

        return [
            [this.applicability_report_user_addressees, this.applicability_report_group_addressees],
        ];
    }

    /**
     * Returns global buttons schema
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.management.diploma-classifications.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(!isEmpty(viewModel.model.id)),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
