import { bindable, inject }               from 'aurelia-framework';
import { AppContainer }                   from 'resources/services/app-container';
import { BaseViewModel }                  from 'base-view-model';
import { LegalConnectionTypesRepository } from './services/repository';

@inject(AppContainer, LegalConnectionTypesRepository)
export class ListLegalConnectionTypes extends BaseViewModel {

    headerTitle = 'listing.legislation.legal-connection-types';
    listingId   = 'legislation-management-legal-connection-types-listing';

    @bindable newRecordRoute = 'legislation.management.legal-connection-types.create';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.management.legal-connection-types.manage',
            'legislation.management.legal-connection-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.legislation.legal-connection-type',
            repository:      this.repository,
            edit:            null,
            destroy:         {
                visible: (row) => this.appContainer.authenticatedUser.isSystemReserved(),
                action:  'legislation.management.legal-connection-types.delete',
            },
            actions:         [
                {
                    tooltip: 'form.button.view',
                    visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.legal-connection-types.manage', 'legislation.management.legal-connection-types.view']),
                    icon:    'icon-file-eye',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.legal-connection-types.view',
                        params: {id: row.id},
                    }),
                },
                {
                    tooltip: 'form.button.edit',
                    visible: (row) => this.appContainer.authenticatedUser.isSystemReserved(),
                    icon:    'icon-pencil7',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.legal-connection-types.edit',
                        params: {id: row.id},
                    }),
                },
            ],
            options:         [],
            buttons:         [],
            destroySelected: false,
            selectable:      true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'legal_connection_types.name',
                    title: 'form.field.direct-designation',
                },
                {
                    data:  'inverse_connection',
                    name:  'inverse_legal_connection_types.name',
                    title: 'form.field.inverse-designation',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }
}
