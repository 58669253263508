import { bindable, inject }                from 'aurelia-framework';
import { LegislationIndicatorsRepository } from 'modules/legislation/indicators/services/repository';

@inject(LegislationIndicatorsRepository)
export class LegislationIndicators {

    @bindable indicators;

    /**
     * Constructor
     *
     * @param repository
     */
    constructor(repository) {
        this.repository = repository;
    }

    /**
     * Handles bind event
     */
    bind() {
        return this.repository.indicators().then(indicators => this.indicators = indicators);
    }

}
