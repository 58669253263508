import { inject }                    from 'aurelia-framework';
import { AuthenticatedUser }         from 'resources/services/authenticated-user';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { DiplomaClassification }     from 'modules/legislation/models/diploma-classification';
import { CountriesRepository }       from 'modules/administration/countries/services/repository';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';
import { UserGroupsRepository }      from 'modules/administration/user-groups/services/repository';
import { DialogService }             from 'aurelia-dialog';

@inject(AuthenticatedUser, CountriesRepository, BooleanStatusesRepository, UserGroupsRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param authenticatedUser
     * @param countriesRepository
     * @param booleanStatusesRepository
     * @param userGroupsRepository
     * @param dialogService
     */
    constructor(authenticatedUser, countriesRepository, booleanStatusesRepository, userGroupsRepository, dialogService) {
        this.authenticatedUser         = authenticatedUser;
        this.countriesRepository       = countriesRepository;
        this.booleanStatusesRepository = booleanStatusesRepository;
        this.userGroupsRepository      = userGroupsRepository;
        this.dialogService             = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {DiplomaClassification}
     */
    model() {
        let model = new DiplomaClassification();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns general form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    generalSchema(viewModel) {
        this.order = {
            type:       'text',
            key:        'order',
            label:      'form.field.order',
            size:       2,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.parent_id = {
            type:       'diploma-classifications-select',
            key:        'parent_id',
            label:      'form.field.branch',
            size:       8,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         4,
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
            hidden:       isNumeric(viewModel.model.parent_id),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.diploma_classification_mapping = {
            type:         'fancytree',
            key:          'diploma_classification_mapping',
            label:        'form.field.original-tree-mapping',
            size:         12,
            remoteSource: viewModel.repository.originalTree.bind(viewModel.repository),
            hidden:       isEmpty(this.authenticatedUser.currentTenant()),
            settings:     {
                repository: viewModel.repository,
            },
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.order, this.name],
            [this.parent_id, this.country_id],
            [this.diploma_classification_mapping],
            [this.status_id],
        ];
    }

    /**
     * Returns daily legislation report addressees form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    dailyLegislationReportAddresseesSchema(viewModel) {
        this.daily_legislation_report_user_addressees = {
            type:       'users-duallistbox',
            key:        'daily_legislation_report_user_addressees',
            label:      'form.field.users',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.daily_legislation_report_group_addressees = {
            type:         'duallistbox',
            key:          'daily_legislation_report_group_addressees',
            label:        'form.field.groups',
            size:         6,
            remoteSource: this.userGroupsRepository.active.bind(this.userGroupsRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.daily_legislation_report_user_addressees, this.daily_legislation_report_group_addressees],
        ];
    }

    /**
     * Returns monthly legislation report addressees form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    monthlyLegislationReportAddresseesSchema(viewModel) {
        this.monthly_legislation_report_user_addressees = {
            type:       'users-duallistbox',
            key:        'monthly_legislation_report_user_addressees',
            label:      'form.field.users',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.monthly_legislation_report_group_addressees = {
            type:         'duallistbox',
            key:          'monthly_legislation_report_group_addressees',
            label:        'form.field.groups',
            size:         6,
            remoteSource: this.userGroupsRepository.active.bind(this.userGroupsRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.monthly_legislation_report_user_addressees, this.monthly_legislation_report_group_addressees],
        ];
    }

    /**
     * Returns global buttons schema
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.management.diploma-classifications.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
