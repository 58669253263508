import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class GlobalMessagesRepository {

    baseUrl = 'legislation/jurist/global-messages';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Updates the mail settings
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post(`${this.baseUrl}`, data);
    }
}
