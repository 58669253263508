import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseViewModel }              from 'base-view-model';
import { SourcesRepository }          from 'modules/legislation/management/sources/services/repository';

@inject(AppContainer, SourcesRepository)
export class ListSources extends BaseViewModel {

    headerTitle = 'listing.legislation.sources';
    listingId   = 'legislation-management-sources-listing';

    @bindable newRecordRoute = 'legislation.management.sources.create';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.management.sources.manage',
            'legislation.management.sources.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.legislation.source',
            repository: this.repository,
            edit:       null,
            destroy:    {
                visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.sources.manage', 'legislation.management.sources.delete']) && (!row.isSticky || this.appContainer.authenticatedUser.isSystemReserved()),
                action:  'legislation.management.sources.delete',
            },
            actions:    [
                {
                    tooltip: 'form.button.view',
                    visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.sources.manage', 'legislation.management.sources.view']),
                    icon:    'icon-file-eye',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.sources.view',
                        params: {id: row.id},
                    }),
                },
                {
                    tooltip: 'form.button.edit',
                    visible: (row) => this.appContainer.authenticatedUser.can(['legislation.management.sources.manage', 'legislation.management.sources.edit']) && (!row.isSticky || this.appContainer.authenticatedUser.isSystemReserved()),
                    icon:    'icon-pencil7',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.management.sources.edit',
                        params: {id: row.id},
                    }),
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'order',
                    name:  'sources.order',
                    title: 'form.field.order',
                },
                {
                    data:  'name',
                    name:  'sources.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'country',
                    name:  'countries.name',
                    title: 'form.field.country',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
