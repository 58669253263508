import { inject }                    from 'aurelia-framework';
import { Source }                    from 'modules/legislation/models/source';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { CountriesRepository }       from 'modules/administration/countries/services/repository';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';

@inject(CountriesRepository, BooleanStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param countriesRepository
     * @param booleanStatusesRepository
     */
    constructor(countriesRepository, booleanStatusesRepository) {
        this.countriesRepository       = countriesRepository;
        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Source}
     */
    model() {
        let model = new Source();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.order = {
            type:       'number',
            key:        'order',
            label:      'form.field.order',
            size:       2,
            attributes: {
                disabled: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       6,
            attributes: {
                disabled: true,
            },
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         4,
            options:      [],
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.own_flag_id = {
            type:       'file-dropzone',
            key:        'own_flag_id',
            label:      'form.field.flag',
            size:       12,
            url:        viewModel.repository.fileUploadUri(),
            required:   false,
            mockFile:   viewModel.model.existing_own_flag,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.management.sources.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.order, this.name, this.country_id],
            [this.own_flag_id],
            [this.status_id],
            [this.buttons],
        ];
    }

}
