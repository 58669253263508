import { inject }                    from 'aurelia-framework';
import { LegalConnectionType }       from 'modules/legislation/models/legal-connection-type';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';

@inject(BooleanStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param booleanStatusesRepository
     */
    constructor(booleanStatusesRepository) {
        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LegalConnectionType}
     */
    model() {
        let model = new LegalConnectionType();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.direct-designation',
            size:       6,
            attributes: {
                disabled: true,
            },
        };

        this.inverse_connection = {
            type:       'text',
            key:        'inverse_connection',
            label:      'form.field.inverse-designation',
            size:       6,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         6,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.management.legal-connection-types.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.name],
            [this.inverse_connection],
            [this.status_id],
            [this.buttons],
        ];
    }

}
