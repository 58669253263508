import { inject, PLATFORM }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { SourcesRepository } from 'modules/legislation/management/sources/services/repository';
import { FormSchema }        from 'modules/legislation/management/sources/form-schema';

@inject(AppContainer, SourcesRepository, FormSchema)
export class CreateSource extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'legislation-management-sources-creation-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.management.sources.manage',
            'legislation.management.sources.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => this.schema = this.formSchema.schema(this));
    }
}
