import { inject }              from 'aurelia-framework';
import { DiplomaSetting }      from 'modules/legislation/models/diploma-setting';
import { CountriesRepository } from 'modules/administration/countries/services/repository';
import { SourcesRepository }   from 'modules/legislation/management/sources/services/repository';

@inject(CountriesRepository, SourcesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param countriesRepository
     * @param sourcesRepository
     */
    constructor(countriesRepository, sourcesRepository) {
        this.countriesRepository = countriesRepository;
        this.sourcesRepository   = sourcesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {DiplomaSetting}
     */
    model() {
        let model = new DiplomaSetting();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[]}
     */
    schema(viewModel) {
        this.default_country_id = {
            type:         'select2',
            key:          'default_country_id',
            label:        'form.field.diplomas-creation-default-country',
            size:         6,
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
            required:     false,
            observers:    [
                (newValue, oldValue) => this.default_source_id.instance.fetchData().then((options) => this.default_source_id.instance.enable(options.length > 0)),
            ],
        };

        this.default_source_id = {
            type:                   'select2',
            key:                    'default_source_id',
            label:                  'form.field.diplomas-creation-default-source',
            size:                   6,
            remoteSource:           this.sourcesRepository.activeByCountry.bind(this.sourcesRepository),
            remoteSourceParameters: () => viewModel.model.default_country_id,
            required:               false,
            attributes:             {
                disabled: !isNumeric(viewModel.model.default_country_id),
            },
        };

        this.include_wemake_logo_in_reports_id = {
            type:  'boolean-options-select',
            key:   'include_wemake_logo_in_reports_id',
            label: 'form.field.include-wemake-logo-in-reports',
            size:  6,
        };

        return [
            [this.default_country_id],
            [this.default_source_id],
            [this.include_wemake_logo_in_reports_id],
        ];
    }

    /**
     * Returns button form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalSchema(viewModel) {
        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
