import { DiplomasRepository } from 'modules/legislation/diplomas/services/repository';

export class JuristDiplomasRepository extends DiplomasRepository {

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('legislation/jurist/diplomas', data);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/jurist/diplomas/search', criteria);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/jurist/diplomas/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/jurist/diplomas/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/jurist/diplomas/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/jurist/diplomas/destroy-selected', {ids: ids});
    }

}
