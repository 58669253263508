import { inject, PLATFORM }                 from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { BaseFormViewModel }                from 'base-form-view-model';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { FormSchema }                       from 'modules/legislation/management/diploma-classifications/form-schema';

@inject(AppContainer, DiplomaClassificationsRepository, FormSchema)
export class EditDiplomaClassification extends BaseFormViewModel {

    headerTitle    = 'form.title.edit-record';
    newRecordRoute = 'legislation.management.diploma-classifications.create';
    formId         = 'legislation-management-diploma-classifications-edition-form';

    alert  = {};
    model  = {};
    schema = {};

    displayLegislationReportAddressees = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/legislation/management/diploma-classifications/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.management.diploma-classifications.manage',
            'legislation.management.diploma-classifications.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.generalSchema                            = this.formSchema.generalSchema(this);
            this.dailyLegislationReportAddresseesSchema   = this.formSchema.dailyLegislationReportAddresseesSchema(this);
            this.monthlyLegislationReportAddresseesSchema = this.formSchema.monthlyLegislationReportAddresseesSchema(this);
            this.applicabilityReportAddresseesSchema      = this.formSchema.applicabilityReportAddresseesSchema(this);
            this.globalButtonsSchema                      = this.formSchema.globalButtonsSchema(this);
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.evaluateLegislationReportAddresseesVisibility();

        this.formSchema.country_id.instance.hide(isNumeric(this.model.parent_id));
    }

    /**
     * Subscribers observers
     */
    subscribeObservers() {
        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.diploma_classification_mapping)
                .subscribe((splices) => this.evaluateLegislationReportAddresseesVisibility()),
        );
    }

    /**
     * Evaluates whether the legislation report addressees can be displayed
     */
    evaluateLegislationReportAddresseesVisibility() {
        this.displayLegislationReportAddressees = this.appContainer.authenticatedUser.hasDiplomaClassificationSubscribed(this.model.diploma_classification_mapping);
    }

}
