import { inject }                    from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { CreateDiploma }             from 'modules/legislation/diplomas/create/index';
import { JuristDiplomasRepository }  from 'modules/legislation/jurist/diplomas/services/repository';
import { DiplomaSettingsRepository } from 'modules/legislation/management/diploma-settings/services/repository';
import { JuristDiplomaFormSchema }   from 'modules/legislation/jurist/diplomas/form-schema';

@inject(AppContainer, JuristDiplomasRepository, DiplomaSettingsRepository, JuristDiplomaFormSchema)
export class CreateJuristDiploma extends CreateDiploma {

    formId = 'legislation-jurist-diplomas-creation-form';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param settingsRepository
     * @param formSchema
     */
    constructor(appContainer, repository, settingsRepository, formSchema) {
        super(appContainer, repository, settingsRepository, formSchema);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.diplomas.manage',
            'legislation.jurist.diplomas.create',
        ]);
    }

}
