import { inject }                    from 'aurelia-framework';
import { DiplomaType }               from 'modules/legislation/models/diploma-type';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { CountriesRepository }       from 'modules/administration/countries/services/repository';
import { SourcesRepository }         from 'modules/legislation/management/sources/services/repository';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';

@inject(CountriesRepository, SourcesRepository, BooleanStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param countriesRepository
     * @param sourcesRepository
     * @param booleanStatusesRepository
     */
    constructor(countriesRepository, sourcesRepository, booleanStatusesRepository) {
        this.countriesRepository       = countriesRepository;
        this.sourcesRepository         = sourcesRepository;
        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {DiplomaType}
     */
    model() {
        let model = new DiplomaType();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.acronym = {
            type:  'text',
            key:   'acronym',
            label: 'form.field.acronym',
            size:  3,
        };

        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  6,
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         6,
            options:      [],
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
            observers:    [
                () => this.sources.instance.fetchData().then((options) => this.sources.instance.enable(options.length > 0)),
            ],
        };

        this.sources = {
            type:                   'duallistbox',
            key:                    'sources',
            label:                  'form.field.sources',
            size:                   12,
            options:                [],
            remoteSource:           this.sourcesRepository.activeByCountry.bind(this.sourcesRepository),
            remoteSourceParameters: () => viewModel.model.country_id,
            attributes:             {
                disabled: !isNumeric(viewModel.model.country_id),
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         6,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.management.diploma-types.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.acronym],
            [this.name],
            [this.country_id],
            [this.sources],
            [this.status_id],
            [this.buttons],
        ];
    }

}
