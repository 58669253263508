import { inject }                   from 'aurelia-framework';
import { AppContainer }             from 'resources/services/app-container';
import { BaseFormViewModel }        from 'base-form-view-model';
import { GlobalMessagesRepository } from 'modules/legislation/jurist/global-messages/services/repository';
import { FormSchema }               from 'modules/legislation/jurist/global-messages/form-schema';

@inject(AppContainer, GlobalMessagesRepository, FormSchema)
export class EditMailSettings extends BaseFormViewModel {

    headerTitle = 'form.title.new-global-message';
    formId      = 'legislation.jurist.global-messages.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.jurist.global-messages.manage',
            'legislation.jurist.global-messages.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.repository.create(this.model)
            .then(response => {
                if (response.status === true) {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                    this.resetForm(false);
                }
            })
            .catch(console.log.bind(console));
    }
}
