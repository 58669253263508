import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class DiplomaClassificationsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns root diploma classifications
     *
     * @returns {*}
     */
    rootNodes() {
        return this.httpClient.get('legislation/management/diploma-classifications/root-nodes');
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('legislation/management/diploma-classifications/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('legislation/management/diploma-classifications/active');
    }

    /**
     * Returns all records by a given country
     *
     * @returns {*}
     */
    allByCountry(countryId) {
        return this.httpClient.get(`legislation/management/diploma-classifications/all/country/${countryId}`);
    }

    /**
     * Returns active records by a given country
     *
     * @returns {*}
     */
    activeByCountry(countryId) {
        return this.httpClient.get(`legislation/management/diploma-classifications/active/country/${countryId}`);
    }

    /**
     * Returns original tree
     *
     * @returns {*}
     */
    originalTree(collapsed = false) {
        return this.httpClient.get(`legislation/management/diploma-classifications/original-tree${collapsed === true ? '?collapsed=true' : ''}`);
    }

    /**
     * Returns tree
     *
     * @returns {*}
     */
    tree(collapsed = false) {
        return this.httpClient.get(`legislation/management/diploma-classifications/tree${collapsed === true ? '?collapsed=true' : ''}`);
    }

    /**
     * Returns tree by a given country
     *
     * @param countryId
     * @param collapsed
     */
    treeByCountry(countryId, collapsed = false) {
        return this.httpClient.get(`legislation/management/diploma-classifications/tree/country/${countryId}${collapsed === true ? '?collapsed=true' : ''}`);
    }

    /**
     * Returns conformity evaluation tree
     *
     * @param conformityEvaluationId
     * @param collapsed
     *
     * @returns {*}
     */
    conformityEvaluationTree(conformityEvaluationId, collapsed = true) {
        return this.httpClient.get(`legislation/management/diploma-classifications/conformity-evaluation-tree/${conformityEvaluationId}${collapsed === true ? '?collapsed=true' : ''}`);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/management/diploma-classifications/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('legislation/management/diploma-classifications', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/management/diploma-classifications/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/management/diploma-classifications/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/management/diploma-classifications/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/management/diploma-classifications/destroy-selected', {ids: ids});
    }

}
