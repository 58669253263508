import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LegislationIndicatorsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns activity`s indicators
     *
     * @returns {*}
     */
    indicators() {
        return this.httpClient.get('legislation/statistics/indicators');
    }

}
